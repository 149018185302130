<template>
  <div id="app">
    <LoadingScreen v-if="showLoading" @loading-complete="showLoading = false" />
    <LandingPage v-else />
  </div>
</template>

<script>
import LoadingScreen from './components/LoadingScreen.vue'
import LandingPage from './components/LandingPage.vue'
import customCursorImg from '@/assets/GenesisBlock.png';

export default {
  name: 'App',
  components: {
    LoadingScreen,
    LandingPage
  },
  data() {
    return {
      showLoading: true,
      customCursorStyle() {
        return `url(${customCursorImg}), auto`;
      }
    };
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
