<template>
  <div class="main-container" @scroll="handleScroll">
    <section class="landing-page">
      <nav class="navbar">
        <div class="logo">
          <a href="#">
            <img src="../assets/sealtoshis_hybrid_logo.png" alt="Logo">
          </a>
        </div>
        <button class="nav-toggle" @click="toggleNav()">
          <img :src="menuIcon" alt="Menu">
        </button>
        <ul class="nav-links" v-bind:class="{ 'active': isActive }">
          <li>
            <!-- <a href="/" target="_blank">
              <img src="../assets/discord.png" alt="Discord" width="28px">
            </a> -->
          </li>
          <li>
            <a href="https://twitter.com/sealtoshis" target="_blank">
              <img src="../assets/x.png" alt="X" width="20px">
            </a>
          </li>
          <li><a href="#newToOrdinalsSection">New to Ordinals?</a></li>
          <li><a href="#">Inscribe</a></li>
          <li><a href="#aboutSection">About</a></li>
        </ul>
      </nav>

      <h1 class="sealtoshis-text">sealtoshis</h1>

      <div class="grid-container">
        <div class="grid-row" v-for="(row, index) in rows" :key="'row-' + index">
          <div class="grid-item" v-for="(image, idx) in row.images" :key="'img-' + image.id">
            <img :src="image.imageUrl" :alt="'Ordinal Image ' + idx" :class="image.fadeClass" class="ordinal-image">
          </div>
        </div>
      </div>

      <a href="#" class="inscribe-text">Inscribe yours</a>

    </section>

    <section class="about fading-section" :class="{ 'visible': aboutVisible }" id="aboutSection" ref="aboutSection">
      <ul class="about-list">
        <li>5341 ORDINALS</li>
        <li>MINT TBA</li>
        <li>THERE IS NO ROADMAP,</li>
        <li>I LOVE YOU</li>
      </ul>
    </section>

    <section class="new-to-ordinals fading-section" :class="{ 'visible': newToOrdinalsVisible }"
      id="newToOrdinalsSection" ref="newToOrdinalsSection">
      <h2>New to Ordinals?</h2>
      <ul class="new-to-ordinals-list">
        <li>1: Set up an <a href="https://www.xverse.app/" target="_blank">xverse</a> wallet</li>
        <li>2: Fund xverse wallet with BTC (be sure to include enough for each inscription fee)</li>
        <li>3: Allow time for BTC transaction to process 15-45 minutes depending on traffic</li>
        <li>4: Connect xverse wallet to inscription site</li>
        <li>5: Inscribe</li>
      </ul>
    </section>

    <footer class="site-footer">
      <div class="footer-content">
        <!-- <a href="/" target="_blank">
          <img src="../assets/discord.png" alt="Discord" width="25px">
        </a> -->
        <a href="https://twitter.com/sealtoshis" target="_blank">
          <img src="../assets/x.png" alt="X" width="20px">
        </a>
        <a href="#" class="footer-inscribe">Inscribe</a>
      </div>
    </footer>
  </div>
</template>

<script>
import image1 from '@/assets/example1.png';
import image2 from '@/assets/example2.png';
import image3 from '@/assets/example3.png';
import image4 from '@/assets/example4.png';
import image5 from '@/assets/example5.png';
import image6 from '@/assets/example6.png';
import image7 from '@/assets/example7.png';
import image8 from '@/assets/example8.png';
import image9 from '@/assets/example9.png';
import image10 from '@/assets/example10.png';
import image11 from '@/assets/example11.png';
import image12 from '@/assets/example12.png';
import image13 from '@/assets/example13.png';
import image14 from '@/assets/example14.png';
import image15 from '@/assets/example15.png';
import image16 from '@/assets/example16.png';
import image17 from '@/assets/example17.png';
import image18 from '@/assets/example18.png';
import image19 from '@/assets/example19.png';
import image20 from '@/assets/example20.png';
import image21 from '@/assets/example21.png';
import image22 from '@/assets/example22.png';
import image23 from '@/assets/example23.png';
import image24 from '@/assets/example24.png';
import image25 from '@/assets/example25.png';
import image26 from '@/assets/example26.png';
import image27 from '@/assets/example27.png';
import image28 from '@/assets/example28.png';
import image29 from '@/assets/example29.png';
import image30 from '@/assets/example30.png';
import image31 from '@/assets/example31.png';
import image32 from '@/assets/example32.png';
import image33 from '@/assets/example33.png';
import image34 from '@/assets/example34.png';
import image35 from '@/assets/example35.png';
import image36 from '@/assets/example36.png';
import image37 from '@/assets/example37.png';
import image38 from '@/assets/example38.png';
import image39 from '@/assets/example39.png';
import image40 from '@/assets/example40.png';
import image41 from '@/assets/example41.png';
import image42 from '@/assets/example42.png';
import image43 from '@/assets/example43.png';
import image44 from '@/assets/example44.png';
import image45 from '@/assets/example45.png';
import image46 from '@/assets/example46.png';
import image47 from '@/assets/example47.png';
import image48 from '@/assets/example48.png';
import image49 from '@/assets/example49.png';
import image50 from '@/assets/example50.png';
import image51 from '@/assets/example51.png';
import image52 from '@/assets/example52.png';
import image53 from '@/assets/example53.png';
import image54 from '@/assets/example54.png';
import image55 from '@/assets/example55.png';
import image56 from '@/assets/example56.png';
import image57 from '@/assets/example57.png';
import image58 from '@/assets/example58.png';


export default {
  data() {
    return {
      allImages: [
        {
          id: 1, imageUrl: image1,
          fadeClass: ''
        }, {
          id: 2, imageUrl: image2,
          fadeClass: ''
        }, {
          id: 3, imageUrl: image3,
          fadeClass: ''
        }, {
          id: 4, imageUrl: image4,
          fadeClass: ''
        }, {
          id: 5, imageUrl: image5,
          fadeClass: ''
        }, {
          id: 6, imageUrl: image6,
          fadeClass: ''
        }, {
          id: 7, imageUrl: image7,
          fadeClass: ''
        }, {
          id: 8, imageUrl: image8,
          fadeClass: ''
        }, {
          id: 9, imageUrl: image9,
          fadeClass: ''
        }, {
          id: 10, imageUrl: image10,
          fadeClass: ''
        }, {
          id: 11, imageUrl: image11,
          fadeClass: ''
        }, {
          id: 12, imageUrl: image12,
          fadeClass: ''
        }, {
          id: 13, imageUrl: image13,
          fadeClass: ''
        }, {
          id: 14, imageUrl: image14,
          fadeClass: ''
        }, {
          id: 15, imageUrl: image15,
          fadeClass: ''
        }, {
          id: 16, imageUrl: image16,
          fadeClass: ''
        }, {
          id: 17, imageUrl: image17,
          fadeClass: ''
        }, {
          id: 18, imageUrl: image18,
          fadeClass: ''
        }, {
          id: 19, imageUrl: image19,
          fadeClass: ''
        }, {
          id: 20, imageUrl: image20,
          fadeClass: ''
        }, {
          id: 21, imageUrl: image21,
          fadeClass: ''
        }, {
          id: 22, imageUrl: image22,
          fadeClass: ''
        }, {
          id: 23, imageUrl: image23,
          fadeClass: ''
        }, {
          id: 24, imageUrl: image24,
          fadeClass: ''
        }, {
          id: 25, imageUrl: image25,
          fadeClass: ''
        }, {
          id: 26, imageUrl: image26,
          fadeClass: ''
        }, {
          id: 27, imageUrl: image27,
          fadeClass: ''
        }, {
          id: 28, imageUrl: image28,
          fadeClass: ''
        }, {
          id: 29, imageUrl: image29,
          fadeClass: ''
        }, {
          id: 30, imageUrl: image30,
          fadeClass: ''
        }, {
          id: 31, imageUrl: image31,
          fadeClass: ''
        }, {
          id: 32, imageUrl: image32,
          fadeClass: ''
        }, {
          id: 33, imageUrl: image33,
          fadeClass: ''
        }, {
          id: 34, imageUrl: image34,
          fadeClass: ''
        }, {
          id: 35, imageUrl: image35,
          fadeClass: ''
        }, {
          id: 36, imageUrl: image36,
          fadeClass: ''
        }, {
          id: 37, imageUrl: image37,
          fadeClass: ''
        }, {
          id: 38, imageUrl: image38,
          fadeClass: ''
        }, {
          id: 39, imageUrl: image39,
          fadeClass: ''
        }, {
          id: 40, imageUrl: image40,
          fadeClass: ''
        }, {
          id: 41, imageUrl: image41,
          fadeClass: ''
        }, {
          id: 42, imageUrl: image42,
          fadeClass: ''
        }, {
          id: 43, imageUrl: image43,
          fadeClass: ''
        }, {
          id: 44, imageUrl: image44,
          fadeClass: ''
        }, {
          id: 45, imageUrl: image45,
          fadeClass: ''
        }, {
          id: 46, imageUrl: image46,
          fadeClass: ''
        }, {
          id: 47, imageUrl: image47,
          fadeClass: ''
        }, {
          id: 48, imageUrl: image48,
          fadeClass: ''
        }, {
          id: 49, imageUrl: image49,
          fadeClass: ''
        }, {
          id: 50, imageUrl: image50,
          fadeClass: ''
        }, {
          id: 51, imageUrl: image51,
          fadeClass: ''
        }, {
          id: 52, imageUrl: image52,
          fadeClass: ''
        }, {
          id: 53, imageUrl: image53,
          fadeClass: ''
        }, {
          id: 54, imageUrl: image54,
          fadeClass: ''
        }, {
          id: 55, imageUrl: image55,
          fadeClass: ''
        }, {
          id: 56, imageUrl: image56,
          fadeClass: ''
        }, {
          id: 57, imageUrl: image57,
          fadeClass: ''
        }, {
          id: 58, imageUrl: image58,
          fadeClass: ''
        },
      ],
      rows: [
        {
          images: [{ //row 1
            id: 1, imageUrl: image1
          }, {
            id: 2, imageUrl: image2
          }, {
            id: 3, imageUrl: image3
          }, {
            id: 4, imageUrl: image4
          }, {
            id: 5, imageUrl: image5
          }, {
            id: 6, imageUrl: image6
          }, {
            id: 7, imageUrl: image7
          }, {
            id: 8, imageUrl: image8
          }, {
            id: 9, imageUrl: image9
          }, {
            id: 10, imageUrl: image10
          },
          ],
          pool: [ //pool1
            {
              id: 11, imageUrl: image11
            }, {
              id: 12, imageUrl: image12
            }, {
              id: 13, imageUrl: image13
            }, {
              id: 14, imageUrl: image14
            }, {
              id: 15, imageUrl: image15
            }, {
              id: 16, imageUrl: image16
            }, {
              id: 17, imageUrl: image17
            }, {
              id: 18, imageUrl: image18
            }, {
              id: 19, imageUrl: image19
            }, {
              id: 20, imageUrl: image20
            },
          ]
        },
        {
          images: [//row2
            {
              id: 21, imageUrl: image21
            }, {
              id: 22, imageUrl: image22
            }, {
              id: 23, imageUrl: image23
            }, {
              id: 24, imageUrl: image24
            }, {
              id: 25, imageUrl: image25
            }, {
              id: 26, imageUrl: image26
            }, {
              id: 27, imageUrl: image27
            }, {
              id: 28, imageUrl: image28
            }, {
              id: 29, imageUrl: image29
            }, {
              id: 30, imageUrl: image30
            },
          ],
          pool: [ //pool2
            {
              id: 31, imageUrl: image31
            }, {
              id: 32, imageUrl: image32
            }, {
              id: 33, imageUrl: image33
            }, {
              id: 34, imageUrl: image34
            }, {
              id: 35, imageUrl: image35
            }, {
              id: 36, imageUrl: image36
            }, {
              id: 37, imageUrl: image37
            }, {
              id: 38, imageUrl: image38
            }, {
              id: 39, imageUrl: image39
            }, {
              id: 40, imageUrl: image40
            },
          ]
        },
        {
          images: [ //row3
            {
              id: 41, imageUrl: image41
            }, {
              id: 42, imageUrl: image42
            }, {
              id: 43, imageUrl: image43
            }, {
              id: 44, imageUrl: image44
            }, {
              id: 45, imageUrl: image45
            }, {
              id: 46, imageUrl: image46
            }, {
              id: 47, imageUrl: image47
            }, {
              id: 48, imageUrl: image48
            }, {
              id: 49, imageUrl: image49
            }, {
              id: 50, imageUrl: image50
            },
          ],
          pool: [//pool3
            {
              id: 51, imageUrl: image51
            }, {
              id: 52, imageUrl: image52
            }, {
              id: 53, imageUrl: image53
            }, {
              id: 54, imageUrl: image54
            }, {
              id: 55, imageUrl: image55
            }, {
              id: 56, imageUrl: image56
            }, {
              id: 57, imageUrl: image57
            }, {
              id: 58, imageUrl: image58
            },
          ]
        },
      ],
      usedImages: [],
      ordinalCollectionRow1: [],
      ordinalCollectionRow2: [],
      ordinalCollectionRow3: [],
      swapInterval: null,
      isActive: false,
      aboutVisible: false,
      newToOrdinalsVisible: false,
      menuIcon: require('@/assets/orange-menu.png'),
    };
  },
  created() {
    this.initializeRows();
  },
  methods: {
    initializeRows() {
      const totalImages = this.allImages.length;
      const imagesPerRow = Math.floor(totalImages / this.rows.length);
      let imageIndex = 0;

      this.rows.forEach(row => {
        // Assign each row its unique images
        row.images = this.allImages.slice(imageIndex, imageIndex + imagesPerRow);
        imageIndex += imagesPerRow;
      });

      // Handle remaining images by adding them to the pools or as needed
      const remainingImages = this.allImages.slice(imageIndex);
      this.rows.forEach((row, index) => {
        const poolStart = index * imagesPerRow;
        const poolEnd = poolStart + imagesPerRow;
        row.pool = this.allImages.slice(poolStart, poolEnd).concat(remainingImages);
      });
    },
    swapRandomImage() {
      this.rows.forEach(row => {
        if (row.images.length > 0 && row.pool.length > 0) {
          const swapOutIndex = Math.floor(Math.random() * row.images.length);
          const swapOutImage = row.images[swapOutIndex];
          const availableImages = row.pool.filter(p => !row.images.some(img => img.id === p.id));

          if (availableImages.length > 0) {
            const swapInIndex = Math.floor(Math.random() * availableImages.length);
            const swapInImage = availableImages[swapInIndex];

            // Manage classes for fade effect
            row.images[swapOutIndex].fadeClass = 'fade-out';
            setTimeout(() => {
              const newImage = { ...swapInImage, fadeClass: 'fade-in' };
              row.images.splice(swapOutIndex, 1, newImage); // This is reactive

              // Clear the fade-in class after the animation is complete
              setTimeout(() => {
                row.images[swapOutIndex].fadeClass = '';
              }, 600); // matches CSS animation time
            }, 600);

            // Update the pool
            row.pool.push({ ...swapOutImage, fadeClass: '' });
            row.pool.splice(row.pool.findIndex(p => p.id === swapInImage.id), 1);
          }
        }
      });
    },


    toggleNav() {
      this.isActive = !this.isActive;
    },
    handleScroll() {
      this.checkVisibility(this.$refs.aboutSection, 'aboutVisible');
      this.checkVisibility(this.$refs.newToOrdinalsSection, 'newToOrdinalsVisible');
    },
    checkVisibility(element, dataKey) {
      const rect = element.getBoundingClientRect();
      const isVisible = (rect.top <= window.innerHeight) && (rect.bottom >= 0);
      this[dataKey] = isVisible;
    },
  },
  mounted() {
    this.initializeRows();
    this.swapInterval = setInterval(this.swapRandomImage, 3500);
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeMount() {
    clearInterval(this.swapInterval);
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>


<style>
.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: white;
  font-family: "VT323", monospace;

  position: relative;
  height: 100vh;
  padding-top: 275px;
  overflow: hidden;
  user-select: none;
}


.main-container {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
}

section {
  min-height: 100vh;
  width: 100%;
  scroll-snap-align: start;
}

.fading-section {
  transition: opacity 3s ease-in-out;
  opacity: 0;
}

.fading-section.visible {
  opacity: 1;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-right: 40px;
  color: white;
  font-weight: 900;
  font-size: 1.8em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
}

.nav-toggle {
  display: none;
  background: none;
  border: none;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.nav-toggle img {
  width: 100%;
  height: auto;
}

.logo img {
  width: 195px;
  height: auto;
  padding-left: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 22px;
  transition: transform 0.3s ease-in-out;
}

.nav-links li:nth-child(1) {
  margin-top: 11px;
}

.nav-links li:nth-child(2) {
  margin-top: 11px;
}

.nav-links li:nth-child(4) {
  color: #F7931A;
}

.nav-links.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: black;
  width: 100%;
  top: 60px;
  right: 0;
  padding: 10px 0;
}

.nav-links.active li {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.nav-links.active li img {
  margin: auto;
}


/* Heading */
.sealtoshis-text {
  font-family: "VT323", monospace;
  color: #F7931A;
  text-shadow: 4px 0px 0px #F7931A, 0px 4px 0px #F7931A, -4px 0px 0px #F7931A, -9px 2px 0px #f7be79;
  font-size: clamp(50px, 20vw, 175px);
  text-align: center;
  position: absolute;
  width: 100%;
  top: 12.5vh;
  transform: translateY(-20%);
  pointer-events: none;
  z-index: 10;
}

/* Ordinals displayed */
.grid-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1400px;
  padding-left: 40px;
  padding-right: 40px;
}

.grid-row {
  display: flex;
  width: 100%;
  animation: scrollLeft 45s linear infinite;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.grid-row:nth-child(2) {
  animation-name: scrollRight;
}

.grid-item {
  flex: 0 0 auto;
  width: 240px;
}

.ordinal-image {
  width: 100%;
  height: auto;
  display: block;
  border: 3px solid black;
  border-radius: 7px;
}

.fade-in {
  animation: fadeInEffect 0.6s ease forwards;
}

.fade-out {
  animation: fadeOutEffect 0.6s ease forwards;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOutEffect {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Inscribe text */
.inscribe-text {
  display: inline-block;
  margin: 20px auto 0;
  padding: 0 40px;
  max-width: 1200px;
  font-family: "VT323", monospace;

  font-weight: 900;
  font-size: 3rem;
  text-align: center;
  color: transparent;
  text-decoration: none;
  background: linear-gradient(90deg, transparent, #F7931A, transparent);
  background-repeat: no-repeat;
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: liquid-color 3s linear infinite;
  cursor: pointer;
  user-select: none;
}

@keyframes liquid-color {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

/* Section 2: About Us */
.about {
  height: auto;
  font-size: clamp(50px, 3vw, 175px);
  color: white;
  background-color: black;
  text-align: center;
  padding: 50px 50px 10px;
  font-family: "VT323", monospace;

  font-weight: 500;
  user-select: none;
}

/* Specific styles for the about list */
.about-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.about-list li {
  margin-bottom: -20px;
  color: white;
  font-size: clamp(145px, 10vw, 500px);
  text-shadow: 4px 0px 0px #ffffff, 0px 4px 0px #ffffff, -4px 0px 0px #ffffff, 0px -4px 0px #ffffff;

}

.about-list li:nth-child(2) {
  color: #F7931A;
  text-shadow: 4px 0px 0px #F7931A, 0px 4px 0px #F7931A, -4px 0px 0px #F7931A, 0px -4px 0px #F7931A;
}

.about-list li:nth-child(4) {
  color: #F7931A;
  text-shadow: 4px 0px 0px #F7931A, 0px 4px 0px #F7931A, -4px 0px 0px #F7931A, 0px -4px 0px #F7931A;
}

.about-list li:nth-child(5) {
  color: #F7931A;
  text-shadow: 4px 0px 0px #F7931A, 0px 4px 0px #F7931A, -4px 0px 0px #F7931A, 0px -4px 0px #F7931A;
}

.about-list li:last-child {
  margin-bottom: 505PX;
}



/* Section 3: New to Ordinals? */
.new-to-ordinals {
  height: auto;
  min-height: auto;
  padding: 50px 50px 50px 50px;
  font-size: clamp(90px, 5vw, 350px);
  color: white;
  background-color: black;
  text-align: center;
  padding: 50px 50px 0px 50px;
  font-family: "VT323", monospace;
  font-weight: 900;
  user-select: none;
}

.new-to-ordinals-list {
  font-size: clamp(50px, 2.5vw, 120px);
  color: white;
  padding: 0;
  margin: 0;
}

.new-to-ordinals-list li {
  margin-bottom: 20px;
  padding: 0;
}

.new-to-ordinals-list li:last-child {
  margin-bottom: 450px;
}

.new-to-ordinals-list a {
  color: #F7931A;
  text-decoration: none;
}

.site-footer {
  font-family: "VT323", monospace;

  font-size: clamp(25px, 1vw, 50px);
  font-weight: 900;
  width: 100%;
  padding: 20px 0;
  background-color: black;
  color: #F7931A;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  user-select: none;
}

.footer-content a {
  color: #F7931A;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-inscribe {
  background: linear-gradient(90deg, transparent, #F7931A, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


/* Media Queries for different screen sizes */
@media (max-height: 500px) {

  .about,
  .new-to-ordinals {
    min-height: 50vh;
    padding: 20px;
    font-size: smaller;
  }
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-rows: 0;
  }
}

@media (max-width: 767px) {

  /* Landing Page */
  .landing-page {
    padding-top: 150px;
  }

  /* Navbar */
  .navbar {
    padding-top: 10px;
    padding-right: 15px;
  }

  .nav-toggle {
    display: block;
  }

  .nav-links {
    display: none;

    flex-direction: column;
    width: 100%;
    padding-right: 20px;
  }

  .nav-links.active {
    display: flex;

  }

  .nav-links li {
    width: 100%;
  }

  .nav-links li a {
    text-align: right;
  }

  .logo img {
    width: 110px;
    height: auto;
    padding-left: 20px;
  }

  /* Sealtoshis Header */
  .sealtoshis-text {
    top: 12.5vh;
    transform: translateY(-30%);
    font-size: 18vw;
    text-shadow: 2px 0px 0px #F7931A, 0px 2px 0px #F7931A, -2px 0px 0px #F7931A, 0px -2px 0px #f7be79;
    color: #F7931A;

  }

  /* Grid Container */
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-rows: 0;
  }

  .grid-item {
    width: 180px;
  }

  .ordinal-image {
    width: 100%;
    height: auto;
  }

  .ordinal-image img {
    max-width: 100%;
    height: auto;
  }

  /* Inscription */
  .inscribe-text,
  .grid-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .inscribe-text {
    font-size: 1.5rem;
  }

  .about-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .about-list li {
    margin-bottom: 10px;
    color: white;
    font-size: clamp(58px, 10vw, 500px);
    text-shadow: 1.3px 0px 0px #ffffff, 0px 1.3px 0px #ffffff, -1.3px 0px 0px #ffffff, 0px -1.3px 0px #ffffff;

  }

  .about-list li:nth-child(2) {
    color: #F7931A;
    text-shadow: 1.3px 0px 0px #F7931A, 0px 1.3px 0px #F7931A, -1.3px 0px 0px #F7931A, 0px -1.3px 0px #F7931A;
  }

  .about-list li:nth-child(4) {
    color: #F7931A;
    text-shadow: 1.3px 0px 0px #F7931A, 0px 1.3px 0px #F7931A, -1.3px 0px 0px #F7931A, 0px -1.3px 0px #F7931A;
  }

  .about-list li:nth-child(5) {
    color: #F7931A;
    text-shadow: 1.3px 0px 0px #F7931A, 0px 1.3px 0px #F7931A, -1.3px 0px 0px #F7931A, 0px -1.3px 0px #F7931A;
  }

  .about-list li:last-child {
    margin-bottom: 105PX;
  }

  .new-to-ordinals {
    font-size: clamp(50px, 2.5vw, 500px);
    padding: 20px 20px 60px 20px;
  }

  .new-to-ordinals-list li {
    margin-bottom: 10px;
    font-size: clamp(30px, 2.5vw, 500px);
  }

  .new-to-ordinals-list li:last-child {
    margin-bottom: 20px;
  }

  .site-footer {
    padding: 10px 0;
  }
}
</style>
