<template>
  <div class="flex items-center justify-center h-screen w-screen overflow-hidden bg-black">
    <img v-if="!loadingComplete" src="@/assets/sealtoshis_logo.gif" alt="Loading..." class="loading-gif">
  </div>
</template>


<script>
export default {
  data() {
    return {
      loadingComplete: false,
    };
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    startLoading() {
      const loadingDuration = 5000;
      setTimeout(() => {
        this.completeLoading();
      }, loadingDuration);
    },
    completeLoading() {
      this.loadingComplete = true;
      this.$emit('loading-complete');
      setTimeout(() => {
        console.log("Attempting to navigate to /landing...");
        this.$router.push('/').catch(err => {
          console.error("Failed to navigate:", err);
        });
      },);
    }
  },
};
</script>


<style>
body {
    margin: 0;
    background-color: black;
    color: #F7931A;
}
.loading-gif {
  font-size: clamp(50px, 20vw, 175px);
  height: auto;
}
</style>
